import i18n from "i18next";
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css"; // icons
import "primereact/resources/primereact.css"; // core css
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { initReactI18next } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    createBrowserRouter,
    redirect,
    RouterProvider
} from "react-router-dom";
import ErrorPage from "./404";
import type { Event } from "./app/Events";
import { Unauthorized } from "./app/Unauthorized";
import NotificationDisplay from "./components/NotificationDisplay";
import { NotificationProvider } from "./context/NotificationContext";
import { ResourceProvider } from "./context/ResourcesContext";
import { protectedRouteLoader, Subjects, UserRoles } from "./hooks/useAuth";
import en from "./lang/en.json";
import it from "./lang/it.json";
import { LayoutProvider } from "./layout/context/layoutcontext";

// commented because:
// it is used a custom tag manager,
// only in booking and open day form pages
// const tagManagerArgs = {
//   gtmId: "GTM-5MVX8BX",
// };

// TagManager.initialize(tagManagerArgs);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    it: { translation: it },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

const homePageLoader = async () => {
  const { user } = await protectedRouteLoader();
  switch (user?.role) {
    case UserRoles.eventOperator:
      return redirect("/events");
    case UserRoles.eventAdmin:
      return redirect("/events");
    case UserRoles.admin:
      return redirect("/users");
    case UserRoles.advisor:
      return redirect("/calendar");
    case UserRoles.bookingAdmin:
      return redirect("/calendar");
    case UserRoles.secretary:
      return redirect("/calendar");
    default:
      return redirect("/login");
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    loader: () => homePageLoader(),
  },
  {
    path: "/login",
    lazy: () =>
      import("./app/Login").then((m) => ({
        Component: m.default,
      })),
    errorElement: <ErrorPage />,
  },
  {
    path: "/new_password/:token",
    lazy: () =>
      import("./app/NewPassword").then((m) => ({
        Component: m.default,
      })),
    errorElement: <ErrorPage />,
  },
  {
    path: "/users",
    lazy: () =>
      import("./app/Users").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.users),
    errorElement: <Unauthorized />,
  },
  {
    path: "/students",
    lazy: () =>
      import("./app/Students").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.students),
    errorElement: <Unauthorized />,
  },
  {
    path: "/middleware-calls",
    lazy: () =>
      import("./app/MiddlewareCalls").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.middlewareCalls),
    errorElement: <Unauthorized />,
  },
  {
    path: "/capabilities",
    lazy: () =>
      import("./app/Capabilities").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.capabilities),
    errorElement: <Unauthorized />,
  },
  {
    path: "/courses",
    lazy: () =>
      import("./app/Courses").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.courses),
    errorElement: <Unauthorized />,
  },
  {
    path: "/places",
    lazy: () =>
      import("./app/Places").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.places),
    errorElement: <Unauthorized />,
  },
  {
    path: "/places/:id",
    lazy: () =>
      import("./app/Places/PlaceDetail").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.places),
    errorElement: <Unauthorized />,
  },
  {
    path: "/events",
    lazy: () =>
      import("./app/Events").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.events),
    errorElement: <Unauthorized />,
  },
  {
    path: "/events/:id",
    lazy: () =>
      import("./app/Events/EventDetail").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.events),
    errorElement: <Unauthorized />,
  },
  {
    path: "/events/:id/virtual",
    lazy: () =>
      import("./app/Events/EventDetail/Virtual").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.events),
    errorElement: <Unauthorized />,
  },
  {
    path: "/events/:id/subscriptions",
    lazy: () =>
      import("./app/Events/EventDetail/Subscriptions").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.subscriptions),
    errorElement: <Unauthorized />,
  },
  {
    path: "/events/:id/operators",
    lazy: () =>
      import("./app/Events/EventDetail/Operators").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.operators),
    errorElement: <Unauthorized />,
  },
  {
    path: "/calendar",
    lazy: () =>
      import("./app/Calendar").then((m) => ({
        Component: m.default,
      })),
    loader: () =>
      protectedRouteLoader(Subjects.availabilities) ||
      protectedRouteLoader(Subjects.bookings),
  },
  {
    path: "/statistics",
    lazy: () =>
      import("./app/Statistics").then((m) => ({
        Component: m.default,
      })),
    loader: () => protectedRouteLoader(Subjects.statistics),
  },
  {
    path: "/booking",
    lazy: () =>
      import("./components/BookingForm").then((m) => ({
        Component: m.BookingForm,
      })),
  },
  {
    path: "/open-day",
    lazy: () =>
      import("./components/OpenDayForm").then((m) => ({
        Component: m.OpenDayForm,
      })),
  },
  {
    path: "booking/:bookingId",
    lazy: () =>
      import("./components/DeleteBooking").then((m) => ({
        Component: m.DeleteBooking,
      })),
  },
]);

export const AppContext = React.createContext<{
  currentEvent: Event | null;
  setCurrentEvent: React.Dispatch<React.SetStateAction<Event | null>>;
}>({
  currentEvent: null,
  setCurrentEvent: () => {},
});

const queryClient = new QueryClient();

function App() {
  const [currentEvent, setCurrentEvent] = React.useState<Event | null>(null);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{
            currentEvent,
            setCurrentEvent,
          }}
        >
          <NotificationProvider>
            <NotificationDisplay />
            <LayoutProvider>
              <ResourceProvider>
                <React.StrictMode>
                  <RouterProvider router={router} />
                </React.StrictMode>
              </ResourceProvider>
            </LayoutProvider>
          </NotificationProvider>
        </AppContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
